import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import style from "./services.module.css"
import styled from "./page.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/footer"

const Mobile = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return ( 
  <>
     <Navbar/>
    <header>
      <div className={style.p}>
      <div className={style.heading}>“Often neglected in the rush to get your big idea to market.Prueba can help ensure your App doesn't become another social media  statistic due to poor quality."</div>
      </div>
      </header>
      <div className={styled.content} >
      <h2>{frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }}/> 
    </div>
    <div className={style.width}>
      <Img fluid={data.file.childImageSharp.fluid} alt="logo" />
      </div>
      <Footer />
      </>
      
  )
}
export const query = graphql`
query mobile {
    markdownRemark(fileAbsolutePath: {regex: "/MobileApp.md/"}) {
        html
        frontmatter {
          title
        }
      }
      file(name: { eq: "prueba_mobile_app_testing_detail" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `

export default Mobile